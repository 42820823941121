import { Injectable } from "@angular/core";
import { FileUploader } from "ng2-file-upload";
import { Observable } from "rxjs";
import { GoogleAnalyticsService } from "src/app/core/services/google/google-analytics.service";
import { RepositoryService } from "src/app/core/services/http/repository.service";
import { StateService } from "src/app/core/state-manager/appstateservice";
import { environment } from "src/environments/environment";
import { DataCollectionsSiteResponse, DataCollectionsModel, TimePeriodType } from "../models/datacollections.model";
import { maxUploadFileSize } from 'src/app/common/models/file-definitions';

@Injectable()
export class DataCollectionsServices {
  private uploader: FileUploader;
  public guid: string;
  constructor(private stateService: StateService,
              private repository: RepositoryService,  
              public googleAnalyticsService: GoogleAnalyticsService) { }

  getDataCollections(siteid:number, measureid:number, year?:number, timePeriodType?:string, timePeriod?:number) : Observable<DataCollectionsModel[]> {
    
    var params = "";

    if(year !== null){
      if(params == "")
        params = "?";
      else
        params += "&";
      params += "year=" + year.toString();
    }
    if(timePeriodType !== null){
      if(params == "")
        params = "?";
      else
        params += "&";
      params += "timePeriodType=" + timePeriodType;
    }
    if(timePeriod !== null && timePeriodType !== null){
      if(params == "")
        params = "?";
      else
        params += "&";
      params += "timePeriod=" + timePeriod.toString();
    } 
  
    return this.repository.get(environment.apiBaseURL, 'api/SiteMeasure/siteData/'+ siteid + '/' + measureid + params);
  }

  getDataCollectionForAgeGroup(siteid:number, measureid:number, year:number, ageGroup:string) : Observable<DataCollectionsModel[]> {
  return this.repository.get(environment.apiBaseURL, 'api/SiteMeasure/siteData/'+ siteid + '/' + measureid + '/' +  year+ '/' + ageGroup);
  }

  saveDataCollections(datacollections: DataCollectionsModel[], deleteExisting:boolean){
    return this.repository.post(environment.apiBaseURL, 'api/sitemeasure/managedata' + '?deleteExisting=' + deleteExisting, datacollections);
  }

  deleteDataCollections(datacollections: DataCollectionsModel[]){
    return this.repository.delete(environment.apiBaseURL, 'api/sitemeasure/siteData', datacollections);
  }

  saveSiteResponse(siteresponse: DataCollectionsSiteResponse): Observable<any> {
    return this.repository.post(environment.apiBaseURL, 'api/sitemeasure/siteresponse', siteresponse);
  }

  getSiteResponse(siteid:number) : Observable<DataCollectionsSiteResponse> {
    return this.repository.get(environment.apiBaseURL, 'api/sitemeasure/siteresponse/'+ siteid);
  }

  exportSiteMeasureData(siteId:number): Observable<any>{
    return this.repository.get(environment.apiBaseURL, 'api/sitemeasure/exportsitedata/'+siteId,'blob');
  }
  initBulkUploader(siteId:number) {
    this.guid = this.createGuid();
    this.getGuid().subscribe(data => {
      if (data) {
        this.guid = data;
      }
    });
      this.uploader = new FileUploader({
        url: environment.apiBaseURL + 'api/sitemeasure/importsitedata/'+siteId,
        authToken: this.stateService.getToken(),
        queueLimit: this.queueLimit,
        maxFileSize: this.maxFileSize,
        allowedFileType: ['xls', 'xlsx']
      });
  }
  
  uploadSiteDataFile(data:any,siteId :number): Observable<any>
  {
    const formData = new FormData();
    formData.append('FormFile', data, data.name);
    return this.repository.upload(environment.apiBaseURL, 'api/sitemeasure/importsitedata/'+siteId, formData);
  }
     
    getGuid(): Observable<string> {
        return this.repository.get(environment.apiBaseURL, 'api/Common/guid');
      }
  get bulkUploader(): FileUploader {
    return this.uploader;
  }

  get getUploader(): FileUploader {
    return this.uploader;
  }

  get queueLimit(): number {
    return 10;
  }

  get maxFileSize(): number {
    return maxUploadFileSize.maxFileSize;
  }

  createGuid() {
    const u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
    const guid = [u.substr(0, 8), u.substr(8, 4), '4000-8' + u.substr(13, 3), u.substr(16, 12)].join('-');
    return guid;
  }
}