import { formatDate } from "@angular/common";
import { GroupModel } from "./group.model";
import { SiteModel } from "./site.model";

export class CustomMeasureModel {
    id: number;
    measure:string;
    group:GroupModel;
    lastUpdated: string;
    lastUpdatedDateTime: string;
    definition: string;
    isApproved: boolean;
    isRejected: boolean;
    comments: string;
    site:SiteModel;
    shortMeasure:string;  
    ageAtTimeOf:string; 

    constructor(id:number,
        measure:string,
        group:GroupModel,
        lastUpdated:string,
        definition:string,
        isApproved: boolean,
        isRejected:boolean,
        comments: string,
        shortMeasure:string,
        site:SiteModel
        ,ageAtTimeOf:string
    ){
        this.id=id;
        this.measure = measure;
        this.group = group;
        this.lastUpdated = lastUpdated;
        this.lastUpdatedDateTime = formatDate(Date.now().toString(),"yyyy-MM-dd HH:mm","en-US");
        this.definition=definition;
        this.comments = comments;
        this.site =site;
        this.isApproved =isApproved;
        this.isRejected =isRejected;
        this.shortMeasure= shortMeasure;
        this.ageAtTimeOf = ageAtTimeOf;
    }    
}
